#home {
	.section-hero {
		margin-top: 15px;

		.sub-content {
			background-color: $color-primary;
			border-radius: 12px;
			padding: 50px 40px;
			overflow: hidden;

			@extend %container;

			@include mq(mobile) {
				padding: 25px 15px;
			}

			> h1 {
				color: $color-white;
				font-size: 44px;
				font-weight: $font-weight-medium;
				letter-spacing: 0;
				line-height: 55px;
				margin: 0 auto;
				max-width: 870px;
				text-align: center;
				width: 100%;

				@include mq(tablet-down) {
					font-size: 40px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 50px;
					margin-top: 0;
					text-align: center;
					width: 100%;
				}
			}

			> p {
				color: $color-secondary;
				font-size: 23px;
				font-weight: $font-weight-medium;
				letter-spacing: 0;
				line-height: 27px;
				margin: 35px 0 30px 0;
				text-align: center;

				@include mq(mobile) {
					margin-bottom: 35px;
				}
			}

			.payment-button--mobile {
				display: none;

				@include mq(tablet-down) {
					border: 2px solid $color-white;
					display: flex;
					font-size: 20px;
					height: 65px;
					justify-content: center;
					line-height: 24px;
					margin: 0 0 60px 0;
					max-width: unset;
				}

				> img {
					height: 30px;
					margin-right: 20px;
					width: 27px;
				}
			}

			.hero-image-wrapper {
				@include mq(tablet-down) {
					height: 327px;
					overflow: hidden;
					position: relative;
				}

				.hero-image {
					background-size: cover;
					width: 100%;

					@include mq(tablet-down) {
						position: absolute;
						right: -150px;
						width: unset;
					}

					@include mq(mobile) {
						right: -470px;
					}
				}
			}
		}
	}

	.section-useful-info {
		margin-top: 30px;

		.sub-content {
			@extend %container;

			> h3 {
				color: $color-primary;
				font-size: 22px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0;
				line-height: 55px;

				@include mq(tablet-down) {
					font-size: 28px;
					line-height: 55px;
				}
			}

			ul.list-info-links {
				@extend %list-default;

				display: flex;
				justify-content: space-between;
				position: relative;

				@include mq(tablet-down) {
					flex-direction: column;
				}

				li.item-info-link {
					border-radius: 12px;
					display: flex;
					flex-direction: column;
					height: 165px;
					justify-content: space-between;
					padding: 20px;
					position: relative;
					width: calc((100% / 3) - 18px);

					@include mq(tablet-down) {
						height: 147px;
						justify-content: flex-start;
						margin-bottom: 10px;
						width: 100%;
					}

					@include mq(mobile) {
						justify-content: space-between;
					}

					&--0 {
						background-color: #fee8d7;
					}

					&--1 {
						background-color: #f7dde5;
					}

					&--2 {
						background-color: #e5f2f8;
					}

					> a {
						display: block;
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
					}

					> h3 {
						color: $color-primary;
						font-size: 17px;
						font-weight: $font-weight-medium;
						letter-spacing: 0;
						line-height: 32px;
						margin: 0;

						@include mq(tablet-down) {
							font-size: 20px;
							margin-bottom: 10px;
						}

						@include mq(mobile) {
							margin: 0;
						}
					}

					> p {
						color: $color-primary;
						font-size: 15px;
						font-weight: $font-weight-regular;
						letter-spacing: 0;
						line-height: 26px;
						margin: 0;

						@include mq(tablet-down) {
							font-size: 16.3px;
						}
					}

					> svg {
						height: 15.56px;
						position: absolute;
						right: 16.5px;
						stroke: $color-secondary;
						top: 22px;
						width: 8.64px;

						@include mq(tablet-down) {
							right: 21.5px;
							top: 29px;
						}
					}
				}
			}
		}
	}

	.section-who-are-newlyn {
		margin: 110px 0;

		@include mq(tablet-down) {
			margin: 10px 0 60px 0;
		}

		.sub-content {
			@extend %container;

			display: flex;
			flex-wrap: wrap;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			.content-col-left {
				width: 50%;

				@include mq(tablet-down) {
					width: 100%;
				}

				> h3 {
					color: $color-primary;
					font-size: 22px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0;
					line-height: 55px;
					width: 100%;

					@include mq(tablet-down) {
						font-size: 28px;
						margin: 30px 0 15px 0;
					}
				}

				> p {
					color: $color-primary;
					font-size: 17px;
					font-weight: $font-weight-regular;
					letter-spacing: 0;
					line-height: 32px;
					width: 76%;

					&:last-of-type {
						margin-bottom: 60px;

						@include mq(tablet-down) {
							margin-bottom: 30px;
						}
					}

					@include mq(tablet-down) {
						font-size: 20px;
						width: 100%;
					}
				}

				ul.list-buttons {
					@extend %list-default;

					display: flex;

					@include mq(mobile) {
						flex-direction: column;
					}

					li.item-button {
						position: relative;

						@include mq(mobile) {
							display: flex;
							font-size: 20px;
							height: 65px;
							justify-content: space-between;
							line-height: 32px;
							margin-bottom: 10px;
							max-width: unset;
						}

						&:first-of-type {
							margin-right: 11px;
						}

						> a {
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
						}
					}
				}
			}

			.content-col-right {
				align-items: flex-end;
				display: flex;
				justify-content: center;
				width: 50%;

				@include mq(tablet-down) {
					margin-top: 40px;
					width: 100%;
				}

				> img {
					max-height: 331px;

					@include mq(tablet-down) {
						width: 379px;
					}

					@include mq(mobile) {
						width: 239px;
					}
				}
			}
		}
	}
}
