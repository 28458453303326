#customer-information-subpage {
	.section-hero {
		align-items: center;
		background-color: #fbfbfb;
		display: flex;
		min-height: 169px;
		width: 100%;

		.sub-content {
			@extend %container;

			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;

			.sub-text-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin: 30px 0;
				width: 100%;

				.title {
					align-items: center;
					display: flex;
					margin-bottom: 25px;

					> a {
						align-items: center;
						display: flex;

						> img {
							transform: rotate(180deg);
							margin-right: 20px;
						}
					}

					&.mod-no-pad {
						margin-bottom: 0;
					}

					> h1 {
						color: $color-primary;
						font-size: 24px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0;
						margin-bottom: 0;
						width: 80%;

						@include mq(mobile) {
							line-height: 32px;
							margin-bottom: 15px;
							width: 90%;
						}
					}
				}

				> p {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-regular;
					letter-spacing: 0;
					line-height: 32px;
					margin: 0;

					@include mq(mobile) {}
				}
			}

			.sub-image-wrapper {
				display: flex;
				width: 100%;

				.hero-image {
					display: block;
					width: 100%;

					@include mq(mobile) {
						display: none;
					}
				}

				.hero-image--mobile {
					display: none;

					@include mq(mobile) {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}

	.section-hero-copy-mobile {
		display: none;

		@include mq(mobile) {
			display: block;
			margin: 20px 0 0 0;
		}


		.sub-content {
			@extend %container;

			> p {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
			}
		}
	}

	.section-main {
		margin-bottom: 133.5px;
		margin-top: 60px;

		@include mq(mobile) {
			margin-bottom: 60px;
			margin-top: 40px;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-wrapper-main {
				padding-right: 70px;
				width: calc((100% / 3) * 2);

				@include mq(mobile) {
					padding: 0;
					width: 100%;
				}


				> h3 {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0;
					line-height: 32px;
				}

				> p {
					color: $color-primary;
					font-size: 17px;
					font-weight: $font-weight-regular;
					letter-spacing: 0;
					line-height: 32px;
				}

				> ul {
					color: $color-primary;
					font-size: 17px;
					font-weight: $font-weight-regular;
					letter-spacing: 0;
					line-height: 32px;
				}

				table {
					border-collapse: collapse;
					margin: 15px 0;

					tr {
						td {
							border: 1px solid $color-primary;
							padding: 10px;
						}
					}
				}
			}

			ul.list-faqs {
				@extend %list-default;

				flex: 1;
				margin-right: 104px;
				max-width: 697px;

				@include mq(tablet-down) {
					max-width: none;
					width: 100%;
				}


				li.item-faq {
					border-radius: 7px;
					border: 1px solid $color-primary;
					color: $color-primary;
					cursor: pointer;
					font-size: $font-size-med;
					font-weight: $font-weight-medium;
					height: 59px;
					letter-spacing: 0;
					line-height: 19px;
					margin-bottom: 15px;
					padding: 0 20px;
					width: 100%;

					@include mq(tablet-down) {
						align-items: center;
						border: 1px solid #A58AC0;
						font-size: 17px;
						height: auto;
						min-height: 66px;
						position: relative;
						width: 100%;
					}


					span {
						align-items: center;
						display: flex;
						height: 57px;

						@include mq(tablet-down) {
							min-height: 66px;
							padding-right: 20px;
						}
					}

					.sub-faq-text {
						display: none;
						margin-top: 20px;

						> p {
							color: $color-primary;
							font-size: 15px;
							font-weight: $font-weight-regular;
							letter-spacing: 0;
							line-height: 32px;
						}
					}

					.cross {
						display: block;
						height: 15.75px;
						margin-left: auto;
						pointer-events: none;
						position: relative;
						transition: transform .3s ease;
						width: 15.75px;

						@include mq(tablet-down) {
							position: absolute;
							right: 20px;
							top: 22px;
						}


						&:after {
							transform: rotate(90deg);
						}
					}

					&.active {
						align-items: unset;
						flex-direction: column;
						height: unset;
						position: relative;

						// img {
						// 	transform: rotate(90deg);
						// 	transition: transform .3s ease;
						// }

						.cross {
							position: absolute;
							right: 20px;
							top: 21px;
							transform: rotate(-45deg);
							transition: transform .3s ease;
						}

						.sub-faq-text {
							display: block;
						}
					}
				}
			}

			.sub-wrapper-aside {
				margin-left: auto;
				max-width: 399px;
				width: 100%;

				@include mq(tablet-down) {
					margin-left: 0;
					max-width: none;
					width: 100%;
				}


				> aside {
					background-color: #eaf6fc;
					border-radius: 7px;
					padding: 10px 40px 40px 25px;

					@include mq(mobile) {
						display: none;
					}


					> h3 {
						color: $color-primary;
						font-size: 22px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0;
						line-height: 55px;
						margin-bottom: 0;
					}

					> p {
						color: $color-primary;
						font-size: 20px;
						font-weight: $font-weight-regular;
						letter-spacing: 0;
						line-height: 32px;
						margin-bottom: 30px;

						&:last-of-type {
							margin-bottom: 0;
						}
					}

					> a {
						align-items: center;
						background-color: $color-tertiary;
						border-radius: 7px;
						border: none;
						color: $color-white;
						display: flex;
						font-size: 16px;
						font-weight: $font-weight-medium;
						height: 60px;
						justify-content: center;
						letter-spacing: 0;
						line-height: 19px;
						margin: 30px auto 0;
						max-width: 220px;
						text-align: center;
						width: 100%;

						> img {
							margin-right: 15px;
						}
					}
				}

				> img {
					margin-top: 40px;
				}
			}
		}
	}
}
