form {
	.sub-errors p {
		margin: 0 0 5px;
		font-size: $font-size-small;
		color: $color-validation-error;
	}

	.form-element {
		display: inline-block;
		margin: 0 0 10px;
		overflow: hidden;
		position: relative;
		vertical-align: top;
		width: 100%;
		z-index: 1;

		&.error {
			label {
				color: $color-validation-error;
			}
		}

		&.mod-filled {
			label {
				span {
					animation: input-fade 1s forwards;
				}

				&:after {
					transform: translate3d(0, -1px, 0);
				}
			}
		}

		input, textarea {
			background: transparent;
			position: relative;
			display: block;
			border: none;
			padding: 27px 0 20px;
			font-size: $font-size-large;
			color: $color-text;
			outline: none;
			width: 100%;

			@include autocomplete-fill {
				background-color: $color-white;
				box-shadow: 0 0 0px 1000px $color-white inset;
				-webkit-text-fill-color: $color-text;
				transition: background-color 5000s ease-in-out 0s;
			}


			@include mq(mobile) {
				font-size: $font-size-med;
			}


			&:focus {
				border-color: $color-primary;

				+ label {
					color: $color-primary;
					font-size: $font-size-large;
					font-weight: $font-weight-semibold;
				}

				@include mq(mobile) {
					@include placeholder {
						color: $color-primary;
					}
				}
			}

			@include placeholder {
				color: $color-white;
			}


			@include mq(mobile) {
				padding: 0 10px;

				@include mq(mobile) {
					padding: 25px 0px;
				}


				@include placeholder {
					color: $color-text;
				}
			}
		}

		label {
			font-size: $font-size-large;
			font-weight: $font-weight-regular;
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 0;
			width: 100%;
			height: calc(100% - 1em);
			text-align: left;
			pointer-events: none;

			@include mq(tablet-down) {
				font-size: $font-size-med;
			}


			&:before {
				border-bottom: 1px solid $color-borders-light;
				content: '';
				height: calc(100% - 7px);
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}

			&:after {
				border-bottom: 2px solid $color-primary;
				content: '';
				height: calc(100% - 7px);
				left: -1px;
				position: absolute;
				top: 0;
				transform: translate3d(-100%, -1px, 0);
				transition: all 1s ease;
				width: 100%;
			}

			span {
				display: block;

				@include mq(tablet-down) {
					margin-top: 3px;
				}
			}
		}

		textarea {
			padding: 22px 0 4px;
		}
	}

	.btn.btn-primary {
		margin: 25px 0 0;
		padding: 13px 70px;

		@include mq(mobile) {
			padding: 15px 0;
			width: 100%;
			margin: 55px 0 0;
		}
	}
}

input, select, textarea, button, optgroup {
	font-family: $font-standard !important;
}

input[name="Form_Email"] {
	display: none;
	opacity: 0;
	visibility: hidden;
	height: 0;
}
