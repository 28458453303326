html {
	font-size: $font-size-base;

	body {
		line-height: $line-height-base;
		font-weight: $font-weight-regular;
		font-family: $font-standard;
		background: $color-body;
		color: $color-text;
		font-size: $font-size-standard;
		margin: 0;
		overflow-x: hidden;

		a.anchor {
			display: block;
			visibility: hidden;
			position: relative;
			top: -140px; // Usually height of the header
		}

		&.no-scroll {
			overflow: hidden;
		}
	}

	* {
		box-sizing: border-box;
	}

	.grecaptcha-badge {
		display: none !important;
	}
}
