#vulnerability {
	.section-hero {
		background-color: $color-white;
		width: 100%;

		.sub-content {
			@extend %container;

			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;

			.sub-text-wrapper {
				margin: 30px 0;
				width: 100%;

				> h1 {
					color: $color-primary;
					font-size: 24px;
					font-weight: $font-weight-bold;
					letter-spacing: 0;
					line-height: 55px;
					margin-bottom: 10px;

					@include mq(mobile) {
						margin: 0;
					}
				}

				> p {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-light;
					letter-spacing: 0;
					line-height: 32px;

					@include mq(mobile) {}
				}
			}

			.sub-image-wrapper {
				display: flex;
				width: 100%;

				.hero-image {
					display: block;
					width: 100%;

					@include mq(mobile) {
						display: none;
					}
				}

				.hero-image--mobile {
					display: none;

					@include mq(mobile) {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}

	.section-main {
		margin-bottom: 95px;
		margin-top: 60px;

		@include mq(mobile) {
			margin-bottom: 30px;
			margin-top: 20px;
		}


		.sub-content {
			@extend %container;

			display: flex;
			flex-wrap: wrap;

			.sub-wrapper-main {
				padding-right: 70px;
				width: calc((100% / 3) * 2);

				@include mq(mobile) {
					padding: 0;
					width: 100%;
				}


				> h3 {
					color: $color-primary;
					font-size: 22px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0;
					line-height: 55px;
					margin-bottom: 15px;
					width: 100%;
				}

				> p {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-regular;
					letter-spacing: 0;
					line-height: 32px;
					margin-bottom: 30px;
				}

				ul.list-services {
					@extend %list-default;

					width: calc((100% / 3) * 2);

					@include mq(mobile) {
						width: 100%;
					}


					li.item-service {
						box-sizing: border-box;
						border: 1px solid $color-primary;
						border-radius: 7px;
						color: $color-primary;
						cursor: pointer;
						display: flex;
						flex-wrap: wrap;
						font-size: 16px;
						font-weight: $font-weight-medium;
						height: 59px;
						letter-spacing: 0;
						line-height: 19px;
						margin-bottom: 15px;
						padding: 18px 20px;
						width: 697px;

						@include mq(mobile) {
							width: 100%;
						}


						.sub-service-text {
							display: none;
							margin-top: 20px;

							> p {
								color: $color-primary;
								font-size: 15px;
								font-weight: $font-weight-regular;
								letter-spacing: 0;
								line-height: 32px;

								&:last-of-type {
									margin-bottom: 0;
								}
							}
						}

						.cross {
							display: block;
							height: 15.75px;
							margin-left: auto;
							pointer-events: none;
							position: relative;
							transition: transform .3s ease;
							width: 15.75px;

							&:after {
								transform: rotate(90deg);
							}
						}

						&.active {
							height: unset;

							img {
								transform: rotate(90deg);
								transition: transform .3s ease;
							}

							.cross {
								transform: rotate(45deg);
								transition: transform .3s ease;
							}

							.sub-service-text {
								display: block;
							}
						}
					}
				}
			}

			.sub-wrapper-aside {
				width: calc((100% / 3) * 1);

				@include mq(mobile) {
					margin-top: 20px;
					width: 100%;
				}


				> aside {
					background-color: #eaf6fc;
					border-radius: 7px;
					padding: 10px 40px 40px 25px;

					@include mq(mobile) {
						display: none;
					}


					> h3 {
						color: $color-primary;
						font-size: 22px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0;
						line-height: 55px;
						margin-bottom: 0;
					}

					> p {
						color: $color-primary;
						font-size: 20px;
						font-weight: $font-weight-regular;
						letter-spacing: 0;
						line-height: 32px;
						margin-bottom: 30px;
					}

					> a {
						align-items: center;
						display: flex;
						font-size: 20px;
						font-weight: $font-weight-medium;
						letter-spacing: 0;
						line-height: 37px;
						justify-content: flex-start;
						width: 100%;

						&:first-of-type {
							margin-bottom: 15px;
						}

						> img {
							margin-right: 14px;
						}
					}
				}

				> img {
					margin-top: 40px;
				}
			}
		}
	}

	.section-working-with {
		background-color: $color-neutral;

		.sub-content {
			@extend %container;

			display: flex;
			flex-wrap: wrap;
			padding: 90px 0 112px 0;

			@include mq(mobile) {
				padding: 40px 0 50px 0;
			}


			> h3 {
				color: $color-primary;
				font-size: 22px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0;
				line-height: 55px;
				margin-bottom: 15px;
				width: 100%;
			}

			> p {
				color: $color-primary;
				font-size: 18px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
				margin-bottom: 30px;

				@include mq(mobile) {
					margin: 0;
				}
			}

			ul.list-work-with-options {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-top: 33px;

				li.item-work-with-option {
					align-items: center;
					display: flex;
					margin: 0 20px;
					width: calc(33.33333% - 50px);

					@include mq(mobile) {
						align-items: center;
						margin: 0 0 20px 0;
						width: 100%;
					}


					&:nth-child(3n + 3) {
						margin-right: 0;

						@include mq(mobile) {
							margin: 0 0 20px 0;
						}
					}

					&:nth-child(3n + 1) {
						margin-left: 0;

						@include mq(mobile) {
							margin: 0 0 20px 0;
						}
					}

					&:nth-child(n + 4) {
						margin-top: 30px;

						@include mq(mobile) {
							margin: 0 0 20px 0;
						}
					}

					> img {
						height: fit-content;

						@include mq(mobile) {
							height: 89px;
							width: 89px;
						}
					}

					.text-wrapper {
						margin-left: 23px;

						> h3 {
							color: $color-primary;
							font-size: 17px;
							font-weight: $font-weight-semibold;
							letter-spacing: 0;
							line-height: 21px;
							margin-bottom: 12px;

							@include mq(mobile) {
								font-size: 18px;
								line-height: 32px;
								margin: 0 0 5px 0;
							}
						}

						> p {
							color: $color-primary;
							font-size: 15px;
							font-weight: $font-weight-regular;
							letter-spacing: 0;
							line-height: 22px;

							@include mq(mobile) {
								font-size: 17px;
								line-height: 32px;
							}
						}
					}
				}
			}
		}
	}

	.section-accreditation {
		.sub-content {
			@extend %container;

			padding: 70px 0 95px 0;

			@include mq(mobile) {
				padding: 40px 0 60px 0;
			}


			> h3 {
				color: $color-primary;
				font-size: 22px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0;
				line-height: 55px;
				margin-bottom: 15px;
				width: 100%;

				@include mq(mobile) {
					font-size: 23px;
				}
			}

			> p {
				color: $color-primary;
				font-size: 18px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
				margin-bottom: 30px;

				@include mq(mobile) {
					font-size: 20px;
				}
			}

			.list-accreditations {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.item-accreditation {
					margin: 30px 0;
					width: calc(100% / 8);
				}
			}
		}
	}
}
