.section-cookies {
	max-width: 1000px;

	align-items: center;
	background: $color-neutral-dark;
	border-radius: 6px;
	bottom: 19px;
	display: flex;
	height: 63px;
	left: 50%;
	padding: 0 21px;
	position: fixed;
	transform: translateX(-50%);
	width: 95%;
	// override live chat widget
	z-index: 9999999999;

	@include mq(tablet-down) {
		bottom: 100px;
		height: auto;
		padding: 11px 23px;
	}

	&.mod-hide {
		display: none;
	}

	.sub-content {
		align-items: center;
		display: flex;
		width: 100%;

		@include mq(tablet-down) {
			flex-direction: column;
		}

		.sub-text {
			flex: 1;
			padding-right: 20px;

			@include mq(tablet-down) {
				padding-right: 0;
			}

			& > p {
				color: $color-white;
				font-size: 15px;
				font-weight: $font-weight-regular;
				margin-bottom: 0;

				@include mq(tablet-down) {
					text-align: center;
				}

				a {
					font-weight: $font-weight-semibold;
					color: $color-white;
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 20px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 20px;
			}

			.btn {
				background: $color-white;
				color: $color-black;
				display: inline-block;
				font-size: $font-size-standard;
				font-weight: $font-weight-regular;
				height: 36px;
				transition: background 0.3s ease;
				width: 111px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}

				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}

.header-main {
	align-items: center;
	background: $color-primary;
	display: flex;
	height: 108px;

	.sub-container {
		display: flex;
		justify-content: space-between;
		max-width: 1650px;
		margin: 0 auto;
		width: 90%;

		.mob-hamburger {
			display: none;

			@include mq(tablet-down) {
				align-items: flex-end;
				cursor: pointer;
				display: flex;
				justify-content: flex-end;
				position: relative;
				top: 7px;
				transform: translateY(-50%);
				transition: width 0.3s ease;
				z-index: 999;
			}

			span {
				color: $color-white;
				font-size: 18px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0;
				line-height: 21px;
				margin-left: 10px;
			}

			.menu-icon-wrapper {
				.mob-bar {
					background: $color-white;
					border-radius: 20px;
					display: block;
					height: 3px;
					margin-bottom: 8px;
					opacity: 1;
					transform: rotate(0deg);
					transition: 0.4s ease-in-out;
					width: 35px;
					z-index: 99999;

					@include mq(tablet-down) {
						margin-bottom: 6px;
						width: 29px;
					}
				}

				.mob-bar-1 {
					top: 0;
				}

				.mob-bar-2 {
					top: 8px;
				}

				.mob-bar-3 {
					margin-bottom: 0;
					top: 16px;
					width: 14px;
				}
			}

			&.mod-active {
				position: fixed;
				right: 20px;
				top: 31px;
				z-index: 999999;

				.mob-bar {
					background: $color-white;
				}

				.mob-bar-1 {
					top: 18px;
					position: relative;
					right: 0;
					transform: rotate(135deg);
					width: 30px;
				}

				.mob-bar-2 {
					display: none;
					left: -60px;
					opacity: 0;
				}

				.mob-bar-3 {
					transform: rotate(-135deg);
					top: 10px;
					position: relative;
					right: 0;
					width: 30px;
				}

				& + #nav {
					visibility: visible;
				}

				span {
					display: none;
				}
			}
		}

		#nav {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			width: 100%;

			@include mq(tablet-down) {
				display: none;
			}

			.btn-payment {
				border: 1px solid $color-white;
			}

			ul.list-nav {
				@extend %list-default;

				li {
					display: inline-block;
					padding: 0 24px;

					& > a {
						color: $color-white;
						font-size: 18px;
						font-weight: $font-weight-semibold;
					}
				}
			}
		}

		.nav--mobile {
			background-color: $color-primary;
			display: none;
			flex-direction: column;
			height: 100vh;
			left: 0;
			padding-top: 111px;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 999;

			&.mod-active {
				@include mq(tablet-down) {
					display: flex;
				}
			}

			> .list-nav {
				@extend %list-default;
				margin-top: 30px;
				text-align: center;
				width: 100%;

				> li {
					> a {
						color: $color-white;
						font-size: 20px;
						font-weight: $font-weight-medium;
						letter-spacing: 0;
						line-height: 66px;
						text-align: center;
					}
				}
			}

			> .sub-logo {
				position: absolute;
				top: 15px;
				left: 29px;
			}

			> .btn-payment {
				@include mq(tablet-down) {
					border: 1px solid $color-white;
					display: flex;
					font-size: 20px;
					height: 65px;
					justify-content: center;
					line-height: 24px;
					margin: 0 15px;
					max-width: unset;
					width: unset;
				}

				> img {
					height: 30px;
					margin-right: 20px;
					width: 27px;
				}
			}
		}
	}
}
