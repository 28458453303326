.footer-main {
	background: $color-primary;
	padding: 60px 0;
	z-index: 1;

	@include mq(tablet-down) {
		background-color: $color-white;
		padding: 25px 0;
	}


	.sub-content {
		@extend %container;

		.footer-section-top {
			border-bottom: 1px solid #A58AC0;
			display: flex;
			padding-bottom: 28px;
			width: 100%;

			@include mq(tablet-down) {
				border-bottom-color: $color-primary;
				padding-bottom: 18px;
				padding-top: 20px;
			}


			ul.list-footer-links {
				@extend %list-default;

				display: flex;
				width: 100%;

				@include mq(tablet-down) {
					flex-wrap: wrap;
				}


				> li.item-footer-link {
					align-items: center;
					display: flex;
					margin-right: 45px;

					@include mq(tablet-down) {
						margin-bottom: 10px;
						margin-right: 0;

						&:after {
							content: '|';
							color: #F3A2B9;
							margin: 0px 15px;
						}
					}


					&:last-of-type {
						margin-right: 0;
					}

					> a {
						color: $color-white;
						font-size: 16px;
						font-weight: $font-weight-medium;
						line-height: 66px;

						@include mq(tablet-down) {
							color: $color-primary;
							font-size: 15 / $font-base * 1rem;
							font-weight: $font-weight-medium;
							line-height: unset;
						}
					}

					&:last-child:after {
						content: none;
					}
				}
			}

			.btn.btn-secondary {
				@include mq(tablet-down) {
					display: none;
				}
			}
		}

		.footer-section-bottom {
			display: flex;
			justify-content: space-between;
			padding: 30px 0 10px 0;
			width: 100%;

			@include mq(tablet-down) {
				flex-direction: column-reverse;
			}


			.copyright-text {
				color: rgba($color-white, 0.6);
				display: block;
				font-size: 13px;
				font-weight: $font-weight-light;
				margin: 0;
				max-width: 750px;
				width: 100%;

				@include mq(tablet-down) {
					display: none;
				}


				&--mobile {
					display: none;

					@include mq(tablet-down) {
						color: $color-primary;
						display: block;
						font-size: 15px;
						font-weight: $font-weight-medium;
						line-height: 26px;
					}
				}
			}

			ul.list-footer-terms-links {
				@extend %list-default;

				display: flex;

				@include mq(tablet-down) {
					margin-bottom: 30px;
				}


				& > li.item-footer-terms-link {
					align-items: center;
					display: flex;

					&:after {
						content: '|';
						color: #F3A2B9;
						margin: 0px 15px;
					}

					a {
						color: rgba($color-white, 0.6);
						font-family: Raleway;
						font-size: 15px;
						font-weight: $font-weight-light;
						letter-spacing: 0;
						line-height: 26px;

						@include mq(tablet-down) {
							color: $color-primary;
							font-weight: $font-weight-medium;
						}
					}

					&:last-child:after {
						content: none;
					}
				}
			}
		}
	}
}

.footer-mobile {
	display: none;

	@include mq(tablet-down) {
		background-color: $color-primary;
		display: block;
		padding: 62px 0 25px 0;
	}


	.sub-content {
		@extend %container;

		> p {
			color: $color-white;
			font-size: 20px;
			font-weight: $font-weight-light;
			letter-spacing: 0;
			line-height: 32px;
		}

		ul.list-contact-details {
			@extend %list-default;

			li.item-contact-detail {
				> a {
					align-items: center;
					color: $color-white;
					display: flex;
					font-size: 20px;
					font-weight: $font-weight-regular;
					letter-spacing: 0.6px;
					line-height: 37px;
					margin-top: 15px;
					width: 100%;

					> img {
						height: 30px;
						margin-right: 20px;
					}
				}
			}
		}

		.btn-contact {
			font-size: 20px;
			line-height: 24px;
			margin: 43px 0 0 0;
			width: 100%;

			@include mq(mobile) {
				max-width: unset;
			}
		}
	}
}
