#contact {
	.section-hero {
		background-color: $color-white;
		width: 100%;

		.sub-content {
			@extend %container;
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;

			.sub-text-wrapper {
				margin-top: 30px;
				width: 100%;

				> h1 {
					color: $color-primary;
					font-size: 24px;
					font-weight: $font-weight-bold;
					letter-spacing: 0;
					line-height: 55px;
					margin-bottom: 10px;

					@include mq(mobile) {
						margin-bottom: 20px;
					}
				}

				> p {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-light;
					letter-spacing: 0;
					line-height: 32px;

					@include mq(mobile) {

					}
				}
			}

			.sub-image-wrapper {
				display: flex;
				width: 100%;

				.hero-image {
					display: block;
					width: 100%;

					@include mq(mobile) {
						display: none;
					}
				}

				.hero-image--mobile {
					display: none;

					@include mq(mobile) {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}

	.section-main {
		margin-bottom: 133.5px;
		margin-top: 60px;

		@include mq(mobile) {
			margin-bottom: 60px;
			margin-top: 40px;
		}

		.sub-content {
			@extend %container;

			@include mq(mobile) {
				flex-direction: column-reverse;
			}

			> p {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 29px;
				width: 100%;
			}

			.title-text {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-medium;
				letter-spacing: 0;
				line-height: 32px;
				margin-top: 70px;
			}

			ul.list-contact-options {
				@extend %list-default;
				display: flex;
				flex-wrap: wrap;
				margin-top: 40px;
				width: 100%;

				@include mq(mobile) {
					margin-top: 30px;
					width: 100%;
				}

				li.item-contact-option {
					background-color: #eaf6fc;
					box-sizing: border-box;
					border-radius: 7px;
					color: $color-primary;
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					font-size: 16px;
					font-weight: $font-weight-medium;
					height: 275px;
					letter-spacing: 0;
					line-height: 19px;
					padding: 18px 20px;
					width: 580px;

					&:nth-child(odd) {
						margin: 0 12px 24px 0;

						@include mq(mobile) {
							margin: 0 0 15px 0;
						}
					}

					&:nth-child(even) {
						margin: 0 0 24px 12px;

						@include mq(mobile) {
							margin: 0 0 15px 0;
						}
					}

					@include mq(mobile) {
						height: unset;
						width: 100%;
					}

					> h4 {
						color: $color-primary;
						font-size: 20px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0;
						line-height: 32px;
						margin-bottom: 10px;
					}

					> p {
						color: $color-primary;
						font-size: 16px;
						font-weight: $font-weight-regular;
						letter-spacing: 0;
						line-height: 26px;
						margin: 0;
						width: 100%;

						@include mq(mobile) {
							font-size: 15px;
						}

						> a {
							color: $color-primary;
							font-size: 16px;
							font-weight: $font-weight-regular;
							letter-spacing: 0;
							line-height: 26px;
							margin: 0;
							width: 100%;
							text-decoration: underline;

							@include mq(mobile) {
								font-size: 15px;
							}
						}
					}

					> a {
						align-items: center;
						display: flex;
						font-size: 20px;
						font-weight: $font-weight-medium;
						letter-spacing: 0;
						line-height: 32px;
						margin-top: 15px;
						width: 100%;

						@include mq(mobile) {
							font-size: 16px;
						}

						&:first-of-type {
							margin-top: 20px;
						}

						> img {
							height: 30px;
							margin-right: 20px;

							@include mq(mobile) {
								margin-right: 10px;
							}
						}
					}
				}

				li.image-wrapper {
					align-content: center;
					display: flex;
					justify-content: center;
					height: 275px;
					width: 580px;

					@include mq(mobile) {
						height: unset;
						width: 100%;
					}

					> img {

						@include mq(mobile) {
							width: 100%;
						}
					}
				}
			}
		}
	}
}
