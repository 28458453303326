#how-to-pay {

	.section-hero {
		background-color: #f7f7f7;
		height: 190px;

		@include mq(mobile) {
			background-color: $color-white;
		}

		.sub-content {
			@extend %container;
			align-items: center;
			display: flex;
			height: 100%;
			justify-content: space-between;

			.sub-text-wrapper {
				width: 100%;

				@include mq(mobile) {
					width: 100%;
				}

				> h1 {
					color: $color-primary;
					font-size: 28px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0;
					line-height: 55px;

					@include mq(mobile) {
						margin: 0;
					}
				}

				> h3 {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-regular;
					letter-spacing: 0;
					line-height: 32px;
					width: 100%;

					@include mq(mobile) {
						font-size: 20px;
						letter-spacing: 0;
						line-height: 32px;
					}
				}
			}

			.sub-image-wrapper {
				display: flex;
				justify-content: flex-end;
				width: 50%;

				@include mq(mobile) {
					display: none;
				}

				> a {
					justify-content: center;
				}
			}
		}
	}

	.section-login--mobile {
		display: none;

		@include mq(mobile) {
			display: block;
		}

		.sub-content {
			@extend %container;

			> a {
				font-size: 20px;
				justify-content: center;
				margin: 0;
				max-width: unset;
				text-align: center;
				width: 100%
			}
		}
	}

	.section-ways-to-pay {

		.sub-content {
			@extend %container;
			padding: 45px 0 138px 0;

			@include mq(mobile) {
				padding: 40px 0;
			}

			> h1 {
				color: $color-primary;
				font-size: 22px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0;
				line-height: 55px;
				margin-bottom: 20px;

				@include mq(mobile) {
					font-size: 23px;
				}
			}

			> h3 {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
				margin-top: 50px;

				@include mq(mobile) {
					text-align: center;
				}

				> a {
					text-decoration: underline;
				}
			}

			> p {
				display: none;
			}

			ul.list-ways-to-pay {
				@extend %list-default;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-top: 40px;

				@include mq(mobile) {
					display: none;
				}

				li.item-way-to-pay {
					background-color: #eaf6fc;
					border-radius: 12px;
					height: 290px;
					margin-bottom: 25px;
					padding: 24px;
					width: calc((100% / 2) - 12px);

					@include mq(mobile) {
						display: none;
					}

					> h1 {
						color: $color-primary;
						font-size: 20px;
						font-weight: $font-weight-medium;
						letter-spacing: 0;
						line-height: 32px;
					}

					> ul {


						> li {
							color: $color-primary;
							font-size: 16px;
							font-weight: $font-weight-regular;
							letter-spacing: 0;
							line-height: 32px;
							list-style-type: disc;
							margin: 0;
							padding-left: 20px;

							&::marker {
								font-size: 10px;
							}

							> strong {
								font-weight: $font-weight-bold;
							}
						}
					}
				}
			}

			ul.list-ways-to-pay--mobile {
				@extend %list-default;
				display: none;

				@include mq(mobile) {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}

				li.item-way-to-pay--mobile {
					align-items: center;
					border: 1px solid #A58AC0;
					border-radius: 7px;
					color: $color-primary;
					display: flex;
					font-size: 17px;
					font-weight: $font-weight-medium;
					flex-wrap: nowrap;
					height: unset;
					justify-content: space-between;
					letter-spacing: 0;
					line-height: 19px;
					margin-bottom: 15px;
					padding: 18px 20px;
					width: 100%;


					> h1 {
						color: $color-primary;
						font-size: 20px;
						font-weight: $font-weight-medium;
						letter-spacing: 0;
						line-height: 32px;
						margin: 0;
					}

					.sub-way-to-pay-text {
						display: none;
						margin-top: 20px;

						> p {
							color: $color-primary;
							font-size: 15px;
							font-weight: $font-weight-regular;
							letter-spacing: 0;
							line-height: 32px;
						}

						> ul {


							> li {
								color: $color-primary;
								font-size: 16px;
								font-weight: $font-weight-regular;
								letter-spacing: 0;
								line-height: 32px;
								list-style-type: disc;
								margin: 0;
								padding-left: 20px;

								&::marker {
									font-size: 10px;
								}

								> strong {
									font-weight: $font-weight-bold;
								}
							}
						}
					}

					.cross {
						display: block;
						height: 15.75px;
						margin-left: auto;
						pointer-events: none;
						position: relative;
						transition: transform .3s ease;
						width: 15.75px;

						@include mq(mobile) {
							margin-left: 30px;
						}

						&:after {
							transform: rotate(90deg);
						}
					}

					&.active {
						align-items: unset;
						flex-direction: column;
						height: unset;
						position: relative;

						img {
							transform: rotate(90deg);
							transition: transform .3s ease;
						}

						.cross {
							position: absolute;
							right: 19px;
							top: 26px;
							transform: rotate(-45deg);
							transition: transform .3s ease;
						}

						.sub-way-to-pay-text {
							display: block;
						}
					}
				}
			}
		}
	}

	.section-mobile-footer-text {
		display: none;

		@include mq(mobile) {
			display: block;
		}

		.sub-content {
			@extend %container;
			padding: 0 0 40px 0;

			> p {
				color: $color-primary;
				font-size: 19px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
				text-align: center;
			}
		}

	}
}