#careers {

	.section-hero {
		background-color: $color-white;
		width: 100%;

		.sub-content {
			@extend %container;
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;

			.sub-text-wrapper {
				margin-top: 30px;
				width: 100%;

				> h1 {
					color: $color-primary;
					font-size: 24px;
					font-weight: $font-weight-medium;
					letter-spacing: 0;
					line-height: 55px;

					@include mq(mobile) {
						margin: 0;
					}
				}

				> p {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-light;
					letter-spacing: 0;
					line-height: 32px;

					@include mq(mobile) {

					}
				}
			}

			.sub-image-wrapper {
				display: flex;
				width: 100%;

				.hero-image {
					display: block;
					width: 100%;

					@include mq(mobile) {
						display: none;
					}
				}

				.hero-image--mobile {
					display: none;

					@include mq(mobile) {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}

	.section-hero-copy-mobile {
		display: none;

		@include mq(mobile) {
			display: block;
			margin: 20px 0 0 0;
		}

		.sub-content {
			@extend %container;

			> img {
				width: 100%;
			}

			> p {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
			}
		}
	}

	.section-careers {
		margin: 70px 0 133.5px 0;

		@include mq(mobile) {
			margin-top: 30px;
			margin-bottom: 40px
		}

		.sub-content {
			@extend %container;
			display: flex;
			flex-wrap: wrap;

			> h3 {
				color: $color-primary;
				font-size: 22px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0;
				line-height: 55px;
				margin-bottom: 10px;
				width: 100%;
			}

			> p {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
				margin-bottom: 50px;
				width: 100%;
			}

			.sub-wrapper-main {
				width: calc((100% / 3) * 2);

				@include mq(mobile) {
					width: 100%;
				}

				> h3 {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0;
					line-height: 32px;
				}

				ul.list-careers {
					@extend %list-default;

					li.item-career {

						> h3 {
							color: $color-primary;
							font-size: 20px;
							font-weight: $font-weight-semibold;
							letter-spacing: 0;
							line-height: 32px;

							@include mq(mobile) {
								margin-bottom: 5px;
							}
						}

						> p {
							color: $color-primary;
							font-size: 17px;
							font-weight: $font-weight-regular;
							letter-spacing: 0;
							line-height: 32px;
							margin-bottom: 50px;
							width: 100%;
						}
					}
				}

				ul.list-vacancies {
					@extend %list-default;

					li.item-vacancy {

						> a {
							align-items: center;
							box-sizing: border-box;
							border: 1px solid $color-primary;
							border-radius: 7px;
							display: flex;
							font-size: 16px;
							font-weight: $font-weight-medium;
							height: 59px;
							letter-spacing: 0;
							line-height: 19px;
							padding: 0 20px;
							max-width: 697px;
							width: 100%;

							@include mq(mobile) {
								align-items: center;
								border: 1px solid #A58AC0;
								border-radius: 7px;
								color: $color-primary;
								display: flex;
								font-size: 20px;
								font-weight: $font-weight-medium;
								flex-wrap: nowrap;
								height: unset;
								justify-content: space-between;
								letter-spacing: 0;
								line-height: 19px;
								margin-bottom: 15px;
								padding: 25px 20px;
								width: 100%;
							}

							> img {
								margin-left: auto;
							}
						}
					}
				}
			}

			.sub-wrapper-aside {
				width: calc((100% / 3) * 1);

				@include mq(mobile) {
					margin-top: 30px;
					width: 100%;
				}

				> aside {
					background-color: #eaf6fc;
					border-radius: 7px;
					padding: 10px 60px 40px 25px;

					@include mq(mobile) {
						display: none;
					}

					> h3 {
						color: $color-primary;
						font-size: 22px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0;
						line-height: 55px;
						margin-bottom: 0;

						@include mq(mobile) {
							margin-bottom: 20px;
						}
					}

					> p {
						color: $color-primary;
						font-size: 20px;
						font-weight: $font-weight-regular;
						letter-spacing: 0;
						line-height: 32px;
						margin-bottom: 30px;
					}

					> a {
						display: flex;
						font-size: 20px;
						font-weight: $font-weight-medium;
						letter-spacing: 0;
						line-height: 37px;

						> img {
							margin-right: 15px;
						}
					}
				}

				> img {
					margin-top: 40px;
				}
			}
		}
	}
}