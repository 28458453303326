#about-us {
	.section-hero {
		background-color: $color-white;
		width: 100%;

		.sub-content {
			@extend %container;
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;

			.sub-text-wrapper {
				margin-top: 30px;
				width: 100%;

				> h1 {
					color: $color-primary;
					font-size: 24px;
					font-weight: $font-weight-bold;
					letter-spacing: 0;
					line-height: 55px;
					margin-bottom: 10px;

					@include mq(mobile) {
						margin: 0;
					}
				}

				> p {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-light;
					letter-spacing: 0;
					line-height: 32px;

					@include mq(mobile) {
					}
				}
			}

			.sub-image-wrapper {
				display: flex;
				width: 100%;

				.hero-image {
					display: block;
					width: 100%;

					@include mq(mobile) {
						display: none;
					}
				}

				.hero-image--mobile {
					display: none;

					@include mq(mobile) {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}

	.section-hero-copy-mobile {
		display: none;

		@include mq(mobile) {
			display: block;
			margin: 20px 0 0 0;
		}

		.sub-content {
			@extend %container;

			> img {
				width: 100%;
			}

			> p {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
			}
		}
	}

	.section-main {
		margin-bottom: 100px;

		@include mq(mobile) {
			margin-bottom: 50px;
		}

		.sub-content {
			@extend %container;

			> h3 {
				color: $color-primary;
				font-size: 22px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0;
				line-height: 55px;
				margin-bottom: 10px;
			}

			> h6 {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;

				@include mq(mobile) {
					margin: 10px 0 20px 0;
				}
			}

			> p {
				color: $color-primary;
				font-size: 18px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
			}

			ul.list-values {
				@extend %list-default;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-top: 60px;
				position: relative;

				@include mq(mobile) {
					justify-content: unset;
					margin: 50px 0 30px 0;
				}

				.slick-dots {
					margin: auto;
					width: fit-content;
				}

				li.item-value {
					align-items: flex-start;
					display: flex;
					padding-right: 50px;
					width: calc(100% / 3);

					@include mq(mobile) {
						align-items: center;
						display: flex !important;
						flex-direction: column;
						justify-content: flex-start;
						padding-right: 0;
						width: 50%;
					}

					&:nth-child(3n + 3) {
						margin-right: 0;
					}

					&:nth-child(3n + 1) {
						margin-left: 0;
					}

					&:nth-child(n + 4) {
						margin-top: 30px;
					}

					> img {
						width: fit-content;
					}

					.text-wrapper {
						margin-left: 23px;

						@include mq(mobile) {
							margin: 15px 0 0 0;
							max-width: 190px;
							text-align: center;
							width: 100%;
						}

						> h3 {
							color: $color-primary;
							font-size: 20px;
							font-weight: $font-weight-semibold;
							letter-spacing: 0;
							line-height: 23px;
							margin-bottom: 12px;
						}

						> p {
							color: $color-primary;
							font-size: 17px;
							font-weight: $font-weight-light;
							letter-spacing: 0;
							line-height: 27px;
						}
					}
				}
			}

			.slider-controls {
				display: none;

				@include mq(mobile) {
					align-items: center;
					display: flex;
					justify-content: center;
				}

				> button {
					background-color: $color-white;
					border: none;
					cursor: pointer;
					width: 50px;
				}

				.slide-m-dots {
					margin: 0 20px;

					> .slick-dots {
						> li {
							align-items: center;
							display: flex;

							> button {
								height: 12px;
								width: 12px;
							}
						}

						> .slick-active {
							> button {
								height: 16px;
								width: 16px;
							}
						}
					}
				}
			}
		}
	}

	.section-careers {
		margin-top: 60px;
		margin-bottom: 213.5px;

		@include mq(mobile) {
			margin-bottom: 60px;
		}

		.sub-content {
			@extend %container;
			display: flex;
			flex-wrap: wrap;

			> h3 {
				color: $color-primary;
				font-size: 22px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0;
				line-height: 55px;
				margin-bottom: 10px;
				width: 100%;
			}

			> p {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
				margin-bottom: 50px;
				width: 100%;
			}

			.sub-wrapper-main {
				width: calc((100% / 3) * 2);

				@include mq(mobile) {
					width: 100%;
				}

				> h3 {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0;
					line-height: 32px;
				}

				ul.list-careers {
					@extend %list-default;
					width: 85%;

					@include mq(mobile) {
						width: 100%;
					}

					li.item-career {
						margin-bottom: 30px;

						> h3 {
							color: $color-primary;
							font-size: 20px;
							font-weight: $font-weight-semibold;
							letter-spacing: 0;
							line-height: 32px;
							margin-bottom: 0;
						}

						> p {
							color: $color-primary;
							font-size: 17px;
							font-weight: $font-weight-regular;
							letter-spacing: 0;
							line-height: 32px;
						}
					}
				}

				ul.list-vacancies {
					@extend %list-default;
					width: 85%;

					@include mq(mobile) {
						width: 100%;
					}

					li.item-vacancy {
						@include mq(mobile) {
							width: 100%;
						}

						> a {
							align-items: center;
							box-sizing: border-box;
							border: 1px solid $color-primary;
							border-radius: 7px;
							display: flex;
							font-size: 16px;
							font-weight: $font-weight-medium;
							height: 59px;
							letter-spacing: 0;
							line-height: 19px;
							padding: 0 20px;
							width: 697px;

							@include mq(mobile) {
								width: 100%;
							}

							> img {
								margin-left: auto;
							}
						}
					}
				}
			}

			.sub-wrapper-aside {
				width: calc((100% / 3) * 1);

				@include mq(mobile) {
					display: none;
				}

				> aside {
					background-color: #eaf6fc;
					border-radius: 7px;
					padding: 10px 60px 40px 25px;

					> h3 {
						color: $color-primary;
						font-size: 22px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0;
						line-height: 55px;
						margin-bottom: 0;
					}

					> p {
						color: $color-primary;
						font-size: 20px;
						font-weight: $font-weight-regular;
						letter-spacing: 0;
						line-height: 32px;
						margin-bottom: 30px;
					}

					> a {
						display: flex;
						font-size: 20px;
						font-weight: $font-weight-medium;
						letter-spacing: 0;
						line-height: 37px;

						> img {
							margin-right: 15px;
						}
					}
				}
			}
		}
	}
}
