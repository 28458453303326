#customer-information {

	.section-hero {
		background-color: $color-white;
		width: 100%;

		.sub-content {
			@extend %container;
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;

			.sub-text-wrapper {
				margin-top: 30px;
				width: 100%;

				> h1 {
					color: $color-primary;
					font-size: 24px;
					font-weight: $font-weight-bold;
					letter-spacing: 0;
					line-height: 55px;
					margin-bottom: 10px;

					@include mq(mobile) {
						margin: 0;
					}
				}

				> p {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-light;
					letter-spacing: 0;
					line-height: 32px;

					@include mq(mobile) {

					}
				}
			}

			.sub-image-wrapper {
				display: flex;
				width: 100%;

				.hero-image {
					display: block;
					width: 100%;

					@include mq(mobile) {
						display: none;
					}
				}

				.hero-image--mobile {
					display: none;

					@include mq(mobile) {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}

	.section-hero-copy-mobile {
		display: none;

		@include mq(mobile) {
			display: block;
			margin: 20px 0 0 0;
		}

		.sub-content {
			@extend %container;

			> img {
				width: 100%;
			}

			> p {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
			}
		}
	}

	.section-main {
		margin-bottom: 133.5px;
		margin-top: 60px;

		@include mq(mobile) {
			margin-bottom: 60px;
			margin-top: 40px;
		}

		.sub-content {
			@extend %container;
			display: flex;
			flex-wrap: wrap;

			ul.list-links {
				@extend %list-default;
				width: calc((100% / 3) * 2);

				@include mq(mobile) {
					width: 100%;
				}

				li.item-link {
					margin-bottom: 15px;
					width: 85%;

					@include mq(mobile) {
						width: 100%;
					}

					> a {
						align-items: center;
						box-sizing: border-box;
						border: 1px solid $color-primary;
						border-radius: 7px;
						display: flex;
						font-size: 16px;
						font-weight: $font-weight-medium;
						height: 59px;
						letter-spacing: 0;
						line-height: 19px;
						padding: 0 20px;
						width: 697px;

						@include mq(mobile) {
							align-items: center;
							border: 1px solid #A58AC0;
							border-radius: 7px;
							color: $color-primary;
							display: flex;
							font-size: 20px;
							font-weight: $font-weight-medium;
							flex-wrap: nowrap;
							height: unset;
							justify-content: space-between;
							letter-spacing: 0;
							line-height: 19px;
							margin-bottom: 15px;
							padding: 25px 20px;
							width: 100%;
						}

						> img {
							margin-left: auto;
						}
					}
				}
			}

			.sub-wrapper-aside {
				width: calc((100% / 3) * 1);

				@include mq(mobile) {
					width: 100%;
				}

				> aside {
					background-color: #eaf6fc;
					border-radius: 7px;
					padding: 10px 40px 40px 25px;

					@include mq(mobile) {
						display: none;
					}

					> h3 {
						color: $color-primary;
						font-size: 22px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0;
						line-height: 55px;
						margin-bottom: 0;
					}

					> p {
						color: $color-primary;
						font-size: 20px;
						font-weight: $font-weight-regular;
						letter-spacing: 0;
						line-height: 32px;
						margin-bottom: 30px;
					}

					> a {
						align-items: center;
						justify-content: center;
						margin: 0 auto;
						text-align: center;
					}
				}

				> img {
					margin-top: 40px;
				}
			}
		}
	}
}