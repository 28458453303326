// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large` 
	&-large {
		@extend %container;
		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;
		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}