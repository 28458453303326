#job-description {

	.section-hero {
		background-color: $color-white;
		width: 100%;

		.sub-content {
			@extend %container;
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;

			.sub-text-wrapper {
				margin-top: 30px;
				width: 100%;

				> h1 {
					color: $color-primary;
					font-size: 24px;
					font-weight: $font-weight-medium;
					letter-spacing: 0;
					line-height: 55px;

					@include mq(mobile) {
						margin: 0;
					}
				}

				> p {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-light;
					letter-spacing: 0;
					line-height: 32px;

					@include mq(mobile) {

					}
				}
			}

			.sub-image-wrapper {
				display: flex;
				width: 100%;

				.hero-image {
					display: block;
					width: 100%;

					@include mq(mobile) {
						display: none;
					}
				}

				.hero-image--mobile {
					display: none;

					@include mq(mobile) {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
	.section-hero-copy-mobile {
		display: none;

		@include mq(mobile) {
			display: block;
			margin: 20px 0 0 0;
		}

		.sub-content {
			@extend %container;

			> img {
				width: 100%;
			}

			> p {
				color: $color-primary;
				font-size: 17px;
				font-weight: $font-weight-medium;
				letter-spacing: 0;
				line-height: 32px;
			}

			> h1 {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0;
				line-height: 32px;
			}
		}
	}

	.section-main {
		margin: 53px 0 133.5px 0;

		@include mq(mobile) {
			margin: 30px 0 50px 0;
		}

		.sub-content {
			@extend %container;
			display: flex;
			flex-wrap: wrap;

			@include mq(mobile) {
				flex-direction: column;
			}

			> h3 {
				color: $color-primary;
				font-size: 22px;
				font-weight: $font-weight-semibold;
				letter-spacing: 0;
				line-height: 55px;
				margin-bottom: 10px;
				width: 100%;
			}

			> p {
				color: $color-primary;
				font-size: 20px;
				font-weight: $font-weight-regular;
				letter-spacing: 0;
				line-height: 32px;
				margin-bottom: 50px;
				width: 100%;

				@include mq(mobile) {
					font-size: 17px;
				}
			}

			.sub-wrapper-main {
				padding-right: 70px;
				width: calc((100% / 3) * 2);

				@include mq(mobile) {
					padding: 0;
					width: 100%;
				}

				> h3 {
					color: $color-primary;
					font-size: 20px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0;
					line-height: 32px;
				}

				> p {
					color: $color-primary;
					font-size: 17px;
					font-weight: $font-weight-regular;
					letter-spacing: 0;
					line-height: 32px;
				}

				> ul {
					color: $color-primary;
					font-size: 17px;
					font-weight: $font-weight-regular;
					letter-spacing: 0;
					line-height: 32px;
				}
			}

			.sub-wrapper-aside {
				width: calc((100% / 3) * 1);

				@include mq(mobile) {
					margin-top: 40px;
					width: 100%;
				}

				> aside {
					background-color: #eaf6fc;
					border-radius: 7px;
					padding: 10px 60px 40px 25px;

					@include mq(mobile) {
						display: none;
					}

					> h3 {
						color: $color-primary;
						font-size: 22px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0;
						line-height: 55px;
						margin-bottom: 0;
					}

					> p {
						color: $color-primary;
						font-size: 20px;
						font-weight: $font-weight-regular;
						letter-spacing: 0;
						line-height: 32px;
						margin-bottom: 30px;
					}

					> a {
						display: flex;
						font-size: 20px;
						font-weight: $font-weight-medium;
						letter-spacing: 0;
						line-height: 37px;

						> img {
							margin-right: 15px;
						}
					}
				}

				> img {
					margin-top: 40px;
				}
			}
		}
	}
}