.sub-alert {
	align-items: center;
	border-radius: 6px;
	color: $color-white;
	display: flex;
	padding: 15px 30px;
	margin: 10px auto;
	width: 90%;

	@include mq(mobile) {
		padding: 15px 13px;
		width: 96%;
	}

	> img {
		height: 24px;
		width: 27px;
		transform: scaleX(-1);
	}

	> p {
		margin: 0 25px 0 28px;

		@include mq(mobile) {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 21px;
		}
	}

	> .cross {
		cursor: pointer;
		display: block;
		font-size: 20px;
		margin-left: auto;
		pointer-events: none;
		position: relative;
	}

	&.mod-hide {
		display: none;
	}
}
